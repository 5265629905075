.topbar {
  padding: 8px 24px;
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(1,160,227,1) 100%);
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topbar-user-popover .ant-popover-inner-content {
  padding: 0;
}
