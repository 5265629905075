html {
  --primary-color: #0092e0;
  --selected-item-bg-color: yellow;
  --selected-item-bg-color-light: #46beff;
  --text-color-primary: rgba(0, 0, 0, 0.87);
  --text-color-secondary: rgba(0, 0, 0, 0.54);
  --text-color-white-secondary: rgba(255, 255, 255, 0.54);
  --font-size: 14px;
  --padding: 16px;
  --btn-sm-height: 24px;
  --input-height: 29px;
  --select-input-height: 27px;
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
}

html {
  font-weight: 500;
}

body {
  margin: 0px;
}

/* Removes annoying react-error-overlay */
body>iframe {
  display: none
}

/* Disables mac's 3 finger swipe back */
html, body {
  overscroll-behavior-x: none;
}

#page-loader {
  position: fixed !important;
  display: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.45);
  z-index: 3000;
}

.loader {
  width: 50px;
  --b: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 1px;
  background: conic-gradient(#0000 10%, var(--primary-color)) content-box;
  mask: repeating-conic-gradient(#0000 0deg, #000 1deg 20deg, #0000 21deg 36deg), radial-gradient(farthest-side, #0000 calc(100% - var(--b) - 1px), #000 calc(100% - var(--b)));
  mask-composite: destination-in;
  mask-composite: intersect;
  animation: l4 1s infinite steps(10);
}

@keyframes l4 {
  to {
    transform: rotate(1turn)
  }
}

.ant-input-group .ant-form-item {
  margin-bottom: 0px;
}

:focus-visible {
  outline: none !important;
}

input {
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: 'Roboto', sans-serif;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  border-radius: 2px;
  transition: all 0.2s;
  background-color: transparent;
  width: 100%;
  font-weight: 500;
}

.ant-input-outlined {
  border-width: 1px;
  border-style: solid;
  border-color: #aaa;
}

.ant-input-borderless {
  border: none !important;
}

td .ant-form-item {
  margin-bottom: 0 !important;
}

.ant-menu-horizontal {
  border-bottom: none !important;
}

.ant-table-cell .ant-form-item {
  margin-bottom: 0px;
}

.itemsTable .ant-table-tbody-virtual-holder-inner .ant-table-cell {
  padding: 0px !important;
}

.striped-table .ant-table-body tr:nth-child(2n):not(.ant-table-row-selected) td {
  background-color: #fafafa;
}

.ant-table-thead>tr>th {
  color: var(--text-color-primary) !important;
}

.ant-table-thead>tr>th {
  font-weight: bold !important;
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}

.ant-table-no-container-border .ant-table-container {
  border: none !important;
}

.input-text-align-right {
  text-align: right;
}

.read-only {
  box-shadow: none !important;
  background-color: #eee !important;
}

.custom-input.ant-select:not(.ant-select-disabled):focus-within .ant-select-selector {
  background-color: var(--selected-item-bg-color) !important;
}

input.custom-input:focus,
.ant-input-affix-wrapper-focused.custom-input.ant-input-password {
  background-color: var(--selected-item-bg-color);
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: var(--selected-item-bg-color-light) !important;
}

.ant-select-item-option-content {
  font-weight: 500;
}

.ant-form-vertical .ant-form-item-label {
  padding: 0 !important;
}

table .error-row td {
  border-color: #eeb7b8 !important;
}

.transparent-bg {
  background: transparent !important;
}

input,
.ant-select-selector,
.ant-input-affix-wrapper {
  box-shadow: none !important;
}

.ant-select-selector {
  height: 24px !important;
}

.ant-form-item-has-error .label-float {
  color: #ff7875;
}

.ant-form-item-explain,
.ant-form-item-extra {
  margin-top: 0px !important;
}

.material-icons-outlined {
  vertical-align: -0.125em;
  cursor: pointer;
}

html {
  font-size: var(--font-size);
}

.ant-col {
  width: 100%;
}

.ant-layout {
  background-color: white !important;
}

.ant-select-multiple .ant-select-selection-item {
  height: 20px !important;
  line-height: 20px !important;
}

.ant-select-selection-overflow {
  top: -6px;
}

.ant-select-selection-overflow-item {
  margin-right: 4px;
}

.ag-container .ag-header-cell,
.ag-container .ag-header-group-cell,
.ag-container .ag-ltr .ag-cell {
  border-right: 1px solid #999 !important;
}

.ag-container .ag-ltr .ag-cell.ag-cell-focus:focus {
  border: 2px solid #001128 !important;
}

.ag-container .ag-ltr .ag-full-width-row {
  border-right: 2px solid transparent !important;
  border-left: 2px solid transparent !important;
  padding: 2px 7px !important;
}

.ag-container .ag-ltr .ag-full-width-row.ag-row-focus {
  border: 2px solid #001128 !important;
}

.ag-container .ag-ltr .ag-cell.noFocus.ag-cell-focus {
  background-color: transparent;
}

.ag-container .ag-cell {
  padding: 2px 8px !important;
  color: var(--text-color-primary);
}

.ag-header-cell,
.ag-header-group-cell {
  background: #eee;
  font-weight: 900;
  color: var(--text-color-primary);
  font-size: 1.1rem;
}

.ag-header-group-cell-label,
.ag-header-cell-label {
  justify-content: center;
}

.ag-container {
  font-size: 14px !important;
  font-family: "Roboto", sans-serif !important;
}

.ag-container .ag-row {
  border-color: #999 !important;
}

.ag-container:not(.no-focus) .ag-cell-focus {
  background-color: var(--selected-item-bg-color-light) !important;
}

.ag-container:not(.no-focus) .ag-row-focus.ag-row-selected,
.ag-container:not(.no-focus) .ag-row-selected {
  background-color: var(--selected-item-bg-color) !important;
}

.ag-theme-balham .ag-row-hover {
  background-color: #46beff33 !important;
}

.ag-container .ag-root-wrapper {
  border-color: #999 !important;
}

.ag-container .ag-input-field-input {
  height: 24px !important;
}

.ag-header-cell::after,
.ag-header-group-cell::after {
  display: none !important;
}

.ag-row-pinned:first-child .ag-cell {
  background: var(--selected-item-bg-color);
  font-weight: bold;
}

.ag-row-pinned:last-child .ag-cell {
  background: #eee;
  font-weight: 900;
}

.ag-input-field-input {
  text-transform: uppercase;
}

.ag-container .ag-selection-checkbox {
  margin-left: auto !important;
  margin-right: auto !important;
}

.compact-form .ant-form-item {
  margin-bottom: 4px;
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
}

th {
  text-align: center !important;
}

.ant-table-container {
  border: 1px solid grey !important;
}

.ant-table-summary tr {
  background-color: #eee;
  font-weight: bold;
}

tr.ant-table-measure-row {
  visibility: collapse;
}

.ag-root-wrapper-body.ag-layout-normal {
  height: auto !important;
}

.ag-container:not(.no-focus) .ag-row-focus.error-row .ag-cell,
.ag-container:not(.no-focus) .error-row .ag-cell {
  color: red !important;
}

.ag-container:not(.no-focus) .ag-cell.cell-success {
  background-color: #52b11680 !important;
}

.ag-container:not(.no-focus) .ag-cell.cell-fail {
  background-color: #e23f3380 !important;
}

.ag-container:not(.no-focus) .ag-cell.cell-warn {
  background-color: #fffb00 !important;
}

.ag-container:not(.no-focus) .ag-cell.cell-not-applicable {
  background-color: #fff88880 !important;
}

.ant-layout-sider-children {
  overflow: auto;
}

.ant-layout-sider-trigger {
  background-color: #12114a !important;
  z-index: 0 !important;
}

.ant-menu-sub {
  background-color: #12114a !important;
}

ul>.ant-menu-submenu:hover,
ul>.ant-menu-item:hover,
.ant-menu-submenu-active,
ul>.ant-menu-item-active {
  background-color: goldenrod !important;
}

.ant-menu-submenu,
.ant-menu-submenu-item {
  transition: none !important;
}

.ant-menu-submenu-open {
  color: white !important;
  background-color: goldenrod !important;
}

.ant-menu-item,
.ant-menu-submenu-title {
  line-height: 32px !important;
  height: 32px !important;
}

.ant-menu-root>.ant-menu-item,
.ant-menu-root>li>.ant-menu-submenu-title {
  line-height: 40px !important;
  height: 40px !important;
}

ul.ant-menu>li {
  padding-top: 0px;
  padding-bottom: 0px !important;
}

.ant-layout-content.year-closed {
  background-color: #fff888;
}

.non-intrusive-modal {
  width: 0;
  height: 0;
}

.non-intrusive-modal .ant-modal {
  position: fixed;
}

.non-intrusive-modal .ant-modal-wrap {
  width: 0;
  height: 0;
}

.ag-container .ag-cell.ag-cell-inline-editing {
  height: 100% !important;
  padding: 0px !important;
}

.ag-cell-inline-editing input {
  height: 100% !important;
}

.borderless {
  border: none;
}

.hide-cursor {
  caret-color: transparent;
}

html,
.ant-form,
.ant-form-item,
.ant-input,
.ag-container,
.ant-table.ant-table-small,
.ant-form label {
  font-size: var(--font-size) !important;
}

.ant-table-small td {
  padding: 4px 8px !important;
}

.ant-table-small th.ant-table-cell {
  padding: 4px 8px !important;
}

.ant-btn-sm {
  height: var(--btn-sm-height) !important;
  font-size: var(--font-size) !important;
}

.ant-btn:focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 3px 3px var(--primary-color) !important;
}

input {
  height: var(--input-height) !important;
}

input.select.ant-input {
  height: var(--select-input-height) !important;
}

@media screen and (max-width: 1600px) {
  * {
    --padding: 12px;
    --input-height: 24px;
    --select-input-height: 22px;
  }

  .ant-table-tbody {
    line-height: 1 !important;
  }
}

.ant-upload.ant-upload-select {
  display: block !important;
}

.error-input {
  border-color: #ff4d4f !important;
  background-color: #ffdcdd !important;
}

#notifications {
  z-index: 4000 !important;
  position: fixed !important;
  top: 24px !important;
  left: 50% !important;
  right: auto !important;
  transform: translate(-50%, 0) !important;
  max-height: calc(100vh - 48px);
  overflow: auto;
}

/* workaround to hide unreasonable scrollbars on notification container */
#notifications::-webkit-scrollbar {
  background: transparent;
  width: 0px;
}

#notifications .toast {
  width: 400px;
  z-index: 4000;
  position: relative;
  border-radius: 4px;
  padding: 16px 24px;
  color: white;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, .1), 0 2px 15px 0 rgba(0, 0, 0, .05);
}

.toast.success {
  background-color: #07bc0c;
}

.toast.error {
  background-color: #e74c3c;
}

.toast.warning {
  background-color: #ffb300;
}

.toast.info {
  background-color: #3498db;
}

#notifications>.toast-container {
  margin-bottom: 10px;
  transition: all 0.3s ease-out;
  animation: show_toast 0.3s;
}

#notifications>.toast-container.hide {
  opacity: 0;
  transform: translateX(100%);
  margin: 0;
  height: 0px !important;
}

.toast>.progress {
  position: absolute;
  height: 4px;
  width: 100%;
  bottom: 0px;
  left: 0px;
  background: rgba(255, 255, 255, 0.5);
}

@keyframes show_toast {
  0% {
    transform: translateY(800px);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes progress {
  100% {
    width: 0%;
  }
}

.ant-notification {
  z-index: 3999 !important;
  left: 50% !important;
  right: auto !important;
  transform: translate(-50%, 0) !important;
}

.ant-notification-notice-wrapper {
  position: static !important;
}

.each-slideshow-indicator.active::before {
  background-color: #0092e0 !important;
}

.shortcuts::-webkit-scrollbar {
  display: none;
}