.Option:hover {
  background: #9df4ff;
}
.Option.OptionActive {
  background: var(--selected-item-bg-color-light);
}

.Option {
  padding: 6px 12px;
  cursor: pointer;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: transform 0.15s ease-out;
  white-space: pre;
  height: 22px;
}

.Select {
  transition: all 0.3s;
  display: flex;
  flex-direction: row;
  padding: 0px 8px;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.Select.SelectSearch {
  cursor: text;
}

.Select.SelectBordered {
  border: 1px solid #aaa;
}

.Select.SelectReadOnly {
  background: #eee;
}

.Select.SelectBordered:hover,
.Select.SelectBordered:focus-within {
  border: 1px solid var(--primary-color);
}

.Select > input {
  flex: 1;
  padding: 0;
}

.Select:focus-within {
  background-color: var(--selected-item-bg-color);
}

.SelectDropDown {
  background: white;
  z-index: 1500;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
}

.SelectOptionsContainer {
  overflow: auto;
}

.SelectOptionsContainer::-webkit-scrollbar {
  width: 10px;
}

.SelectOptionsContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.SelectOptionsContainer::-webkit-scrollbar-thumb {
  background: #888;
}

.SelectOptionsContainer::-webkit-scrollbar-thumb:hover {
  background: #555;
}
